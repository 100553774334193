.cdk-overlay-container .cdk-overlay-pane .tql-multi-panel-class-pro {
  top: 79px;
  position: absolute;
  left: 0;
  max-width: 400px !important;
  width: 400px !important;
  min-width: unset !important;
}

.cdk-overlay-container .cdk-overlay-pane .tql-multi-panel-class-novice{
  width: 400px !important;
  overflow: visible;
}

.query-scroll-container {
  .mat-mdc-text-field-wrapper {
    padding: 0 !important;
  }
}

.tql-element-tooltip {
  white-space: pre-line;
}

.blank-chip.mat-mdc-chip.mat-mdc-standard-chip::after {
  background: #0000;
}

trovata-tql-search-bar {

  .mat-mdc-form-field-focus-overlay{
    display: none;
  }

  .query-chip {
    max-width: 100% !important;
    background-color: white;
  }

  .tql-nested-chip {
    border: 3px solid #f5f5f5 !important;
    background-color: white !important;
  }

  .tql-nested-query {
    border-radius: 11px;
    background-color: #f5f5f5;
  }

  .mat-mdc-chip{
    max-width: 100% !important;
  }


  .query-chip-complete {
    background: #f5f5f5 !important;
  }


  .blank-chip {
    max-width: 100% !important;
    background-color: transparent !important;
  }

  .mat-mdc-chip-focus-overlay{
    background-color: transparent !important;
  }

  .tql-search-bar-input {
    display: none !important;
  }

  .mat-mdc-standard-chip .mdc-evolution-chip__action--primary {
    padding-right: 0px;
    padding-left: 0px;
    max-width: 100%;
  }

  .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label{
    max-width: 100%;
    max-height: 30px;
  }
}

.tql-search-bar-tip {
  white-space: normal;
  padding-top: 12px;
}

// styles for outer mat form field for entire search bar
mat-form-field.tql-form-field > .mat-mdc-text-field-wrapper > .mat-mdc-form-field-flex {
  .mat-mdc-form-field-infix {
    padding: 0.4em 6px 0.4em 0;
    min-height: unset;
    width: unset;
  }
}

mat-form-field.tql-form-field > .mat-mdc-text-field-wrapper {
  min-height: 56px;

  .mdc-line-ripple{
    display: none !important;
  }

  .mat-ripple {
    display: none !important;
  }
}

// styles for inner mat form field for tql elements
mat-form-field.tql-element-field{
  .mat-mdc-form-field-subscript-wrapper{
    display: none !important;
  }

  .mdc-text-field--filled{
    background-color: unset;
  }
}

.tql-form-field {
  .tql-element-field{
    .mat-mdc-text-field-wrapper {
      padding: 0 !important;
    }
  }

  .mat-mdc-select-disabled .select-trigger {
    color: black;
  }
}

// pro mode only
.pro-mode {
  .tql-multi-select {
    .mat-mdc-select-arrow {
      border: none;
      display: none;
    }
  }

  .mdc-evolution-chip-set .mdc-evolution-chip{
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
