.reports-list {
  .mat-column-subtype .mat-sort-header-container {
    justify-content: center;
    padding-left: 15px;
  }

  .mat-column-type .mat-sort-header-container {
    justify-content: center;
    padding-left: 15px;
  }

  .mat-column-cadence .mat-sort-header-container {
    justify-content: center;
    padding-left: 15px;
  }

  .mat-column-createdOn .mat-sort-header-container {
    justify-content: center;
    padding-left: 15px;
  }
}

.vector-panel {
  .mat-expansion-indicator:after {
    padding: 4px !important;
  }

  .mat-expansion-panel-header[aria-disabled='true'] {
    color: $dark-primary-text;
    padding: 0 22px 0 12px !important;
  }
}

.report-center-v3 {
  .hide-select .mdc-line-ripple {
    display: none;
  }
  .hide-select .mat-mdc-text-field-wrapper {
    padding-bottom: 0px;
  }
}

.pie-charts-container {
  highcharts-chart {
    overflow: visible !important;

    div {
      overflow: visible !important;
    }

    svg {
      overflow: visible !important;
    }
  }
}

.report-v3-create-grid .card {
  min-width: 558px !important;
}

.schedule-v3-text-field * {
  width: 100%;
}

.options-toggle {
  .mat-button-toggle-button {
    display: flex;
    justify-content: center;
  }
}

.highcharts-drilldown-data-label text{
  text-decoration:none !important;
}

.highcharts-drilldown-axis-label{
  text-decoration: none !important;
  color: rgb(51, 51, 51) !important;
    font-size: 0.8em !important;
    fill: rgb(51, 51, 51) !important;
  font-weight: normal !important;
}

mat-form-field.report-name-input{
  .mat-mdc-form-field-subscript-wrapper{
    display: none !important;
  }
}
