.transactions-grid-tab {
	width: 100vw;
	height: 100%;
	overflow-y: hidden;
	background-color: #fafafa;
	position: relative;

	.grid-container {
		width: 80%;
		margin: 0 10%;
		overflow-x: auto;
	}

	.total-transactions {
		font-weight: 400;
		font-size: 16px;
		margin: 5px 0 10px 0;
		position: relative;
		height: 41px;
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.included-tags-checkbox {
		margin: 0 0 0 24px;
	}

	.download-csv-icon {
		color: #707070;
		transform: scale(0.9);
		cursor: pointer;
	}

	.grid {
		height: calc(100% - 100px);
	}

	.grid-header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-end;
	}

	.copy-button {
		margin: 0 0 10px 0;
	}

	.loading-container {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 10;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-color: #fafafa;
	}

	.hidden {
		visibility: hidden;
	}

	.card-title {
		margin: 10px 0 0 0;
	}

	.loading-bar {
		width: 800px;
		height: 20px;
	}

	.mat-progress-bar {
		margin: 11px 0 0 0;
		height: 20px;
	}

	.loading-text {
		min-height: 72px;
		color: $dark-primary-text;
		margin: 10px 0 40px;
    font-family: 'AkkuratLLWeb';
		font-weight: 500;
		font-size: 24px;
		white-space: pre;
		text-align: center;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-end;
	}

	.loading-text {
		-webkit-animation: balance steps(4, end) 1500ms infinite;
		animation: balance steps(4, end) 1500ms infinite;
	}

	.loading-text:after {
		width: 0px;
		overflow: hidden;
		display: inline-block;
		vertical-align: bottom;
		-webkit-animation: ellipsis2 steps(4, end) 1500ms infinite;
		animation: ellipsis2 steps(4, end) 1500ms infinite;
		content: '...';
		// tech debt: ellipses was causing special characters in display likely due to loading file in cp1252
	}

	.main-trovata-logo {
		height: 200px;
		margin: 70px 0 0 0;
	}

	.main-trovata-text {
		height: 36px;
		margin: 8px 0 0 0;
	}

	@keyframes ellipsis2 {
		to {
			width: 27px;
		}
	}

	@-webkit-keyframes ellipsis2 {
		to {
			width: 27px;
		}
	}

	@keyframes balance {
		to {
			margin-left: 27px;
		}
	}

	@-webkit-keyframes balance {
		to {
			margin-left: 27px;
		}
	}
}

.trovata-ai-export-page {
    .main-container {
		box-sizing: border-box;
        height: 100vh;
        display: flex;
        flex-direction: column;
		padding: 16px;
    }

    .grid-container {
        flex: 1 1 0;
        min-height: 0;
    }

    .header-row {
        display: flex;
    }

    .header-col {
        display: flex;
        align-items: center;
        gap: 24px;
    }

    .flx-end {
        justify-content: flex-end;
    }

    .flx-1 {
        flex: 1;
    }

    .bot-icon {
        position: relative;
        width: 39px;
        height: 39px;

        .icon-center {
            position: absolute;
            top: 3.5px;
            bottom: 3.5px;
            left: 3.5px;
            right: 3.5px;
        }

    }
}
