@import '_variables';

.large-menu mat-expansion-panel .mat-expansion-panel-content .mat-expansion-panel-body {
	padding: 0 24px 0px !important;
}

.large-menu mat-expansion-panel {
	margin: 0px !important;
}

.nav-menu-content-container {
	margin-right: $page-side-margin-normal;
	margin-left: $page-side-margin-normal;
	margin-bottom: $page-bottom-margin;
	min-height: calc(100vh - 120px);
}

.nav-menu-content-container.only-left-wide {
	margin-right: 0px;
	margin-left: $page-side-margin-wide;
	min-height: calc(100vh - 120px);
}

.nav-menu-content-container.only-left-medium {
	margin-right: 0px;
	margin-left: $page-side-margin-medium;
	min-height: calc(100vh - 120px);
}

.nav-menu-content-container.only-left {
	margin-right: 0px;
	margin-left: $page-side-margin-normal;
	min-height: calc(100vh - 120px);
}

.nav-menu-content-container.wide {
	margin-right: $page-side-margin-wide;
	margin-left: $page-side-margin-wide;
	min-height: calc(100vh - 120px);
}


.nav-menu-content-container.no-bottom {
	margin-bottom: 0px;
	min-height: calc(100vh - 120px);
}
