.nav-menu-content-container:has(.gl-tags-page) {
	margin-bottom: 0px;
}

.gl-entry-search-ctrl {
	height: 40px;
	margin-left: 16px;
	margin-right: 16px;
	width: calc(100% - 32px);
	div.mat-mdc-text-field-wrapper.mdc-text-field {
		height: 40px;
	}
	.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix,
	div.mat-mdc-form-field-infix {
		height: 40px;
		padding: 10px 0px;
		min-height: 40px;
	}
}

.gl-entry {
	trovata-paginated-transactions-table:not(:has(div.select-table-header)) mat-card.table-container {
		border-top-left-radius: 0px;
		border-top-right-radius: 0px;
	}
}

.gl-tag-admin {
	.gl-admin-sidenav {
		.search-input {
			.mdc-notched-outline {
				.mdc-floating-label:not(.mdc-floating-label--float-above) {
					top: 20px;
				}
			}
			.mat-mdc-form-field-subscript-wrapper {
				height: 0px;
			}

			div.mat-mdc-text-field-wrapper.mdc-text-field {
				height: 40px;
			}
			.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix,
			div.mat-mdc-form-field-infix {
				height: 40px;
				padding: 10px 0px;
				min-height: 40px;
			}
		}
	}
}

.gl-tag-details {
	.tag-description-ctrl,
	.gl-code-1-ctrl,
	.gl-code-2-ctrl {
		.mat-mdc-form-field-bottom-align {
			display: none;
			height: 0px;
		}
	}

	trovata-paginated-transactions-table:not(:has(div.remove-table-header)) mat-card.table-container {
		border-top-left-radius: 0px;
		border-top-right-radius: 0px;
	}
}
