@import "../helpers/variables";
@import '_env-variables';

trovata-bulk-entity-dialog .mat-horizontal-stepper-header-container {
    margin: 0 25px;
}

trovata-bulk-entity-dialog .mat-step-icon-selected,
trovata-bulk-entity-dialog .mat-step-icon-state-edit {
    background-color: $primary-color !important;
}

/* 'custom-flex-grid' theme for the FlexGrid */

.custom-flex-grid .selected-entity-row-header div .show-on-row-selected {
    display: block;
}

.custom-flex-grid .wj-header.wj-cell {
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
    background-color: #F7F7F7;
}

// header cells
.custom-flex-grid .wj-header.wj-state-multi-selected {
    background: #E0E0E0;
    border-right: 1px solid rgba(0,0,0,.2) !important;
    border-bottom: 1px solid rgba(0,0,0,.2) !important;
}
.custom-flex-grid .wj-header.wj-state-invalid {
    background: #FFE4E8 !important;
    border: none !important;
    border-right: 1px solid rgba(0,0,0,.2) !important;
    border-bottom: 1px solid rgba(0,0,0,.2) !important;
    border-top: none !important;
}
.custom-flex-grid .column-invalid {
    background-color: #FFE4E8 !important;
}
.custom-flex-grid .column-invalid.wj-state-multi-selected {
    background-color: #FFD5DD !important;
}
.custom-flex-grid .wj-header.wj-state-invalid.wj-state-multi-selected {
    background: #FFD5DD !important;
    border-right: 1px solid rgba(0,0,0,.2) !important;
    border-bottom: 1px solid rgba(0,0,0,.2) !important;
}

// error body cells
.custom-flex-grid .wj-cell.wj-state-multi-selected.wj-state-invalid.selected-entity-row-header {
    background: #C2E4FF !important;
}
.custom-flex-grid .wj-cell.wj-state-invalid.wj-state-selected {
    background: #FFECEF !important;
    color: #000;
}
.custom-flex-grid .wj-cell.wj-state-invalid:not(.wj-header):not(.wj-state-multi-selected) {
    border: 1px solid #D5255B;
}
.custom-flex-grid .wj-cell.wj-state-invalid:not(.wj-header):not(.wj-state-multi-selected):has(> input) {
    border: 2px solid #D5255B !important;
}
.custom-flex-grid .wj-cell.wj-state-invalid:not(.wj-header):not(.wj-state-multi-selected):has(> wj-auto-complete) {
    border: 2px solid #D5255B !important;
}

// base cells 
.custom-flex-grid .wj-cell {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    background-color: #fff;
}

.custom-flex-grid .wj-alt:not(.wj-state-selected):not(.wj-state-multi-selected) {
    background-color: #fff;
}

.custom-flex-grid .wj-rowheaders .wj-row:last-of-type .wj-cell,
.custom-flex-grid .wj-cells .wj-row:last-of-type .wj-cell {
    border-bottom: none;
}

// selected cells
.custom-flex-grid .wj-cell.wj-state-multi-selected.selected-entity-column-header {
    background-color: #C2E4FF !important;
}
.custom-flex-grid .wj-cell.wj-state-multi-selected.selected-entity-row-header {
    background: #C2E4FF !important;
}
.custom-flex-grid .wj-cell:not(.wj-state-invalid):has(> input) {
    box-shadow: inset 0 0 0 2px $primary-color;
}
.custom-flex-grid .wj-cell:not(.wj-state-invalid) > wj-auto-complete {
    display: flex;
    border-radius: 0px;
    box-shadow: inset 0 0 0 2px $primary-color;
}
.custom-flex-grid .wj-cell.wj-state-selected:not(.wj-state-invalid) {
    background-color: #FFF !important;
    box-shadow: inset 0 0 0 1px $primary-color;
    color: #000;
}
.custom-flex-grid .wj-cell.wj-state-selected:not(.wj-state-invalid).first-cell {
    background-color: #C2E4FF !important;
    box-shadow: none !important;
}

.custom-flex-grid .wj-cells .wj-cell.wj-state-multi-selected {
    color: #000;
    background-color: #D8EDFF;
}


// changed cells
.custom-flex-grid .wj-cell.cell-changed:not(.wj-state-invalid):has(> input) {
    box-shadow: inset 0 0 0 2px #00813C;
    background-color: #fff !important;
}
.custom-flex-grid .wj-cell.cell-changed:not(.wj-state-invalid) > wj-auto-complete {
    display: flex;
    border-radius: 0px;
    box-shadow: inset 0 0 0 2px #00813C;
}
.custom-flex-grid .wj-cell.wj-state-selected.cell-changed:not(.wj-state-invalid)  {
    background-color: #E8F9EC !important;
    box-shadow: inset 0 0 0 1px #00813C;
}

.custom-flex-grid .cell-changed:not(.wj-state-invalid) {
    background-color: #E8F9EC !important;
}

.custom-flex-grid .cell-changed.wj-state-multi-selected:not(.wj-state-invalid) {
    background-color: #D1F4D9 !important;
}


// marked for deletion cells
.custom-flex-grid .wj-cell.wj-state-multi-selected.selected-entity-row-header.marked-for-deletion {
    background: #FFCD8C !important;
}
.custom-flex-grid .wj-cell.wj-header.marked-for-deletion {
    background-color: #FFF3E2 !important;
}
.custom-flex-grid .wj-cell.wj-state-selected.marked-for-deletion {
    box-shadow: inset 0 0 0 1px #A95C00;
    background-color: #FFF3E2 !important;
}
.custom-flex-grid .wj-cell.wj-state-multi-selected.marked-for-deletion {
    background-color: #FFE8C9 !important;
}
.custom-flex-grid .marked-for-deletion:not(.wj-header){
    background-color: #FFF3E2 !important;
    text-decoration: line-through;
}
.custom-flex-grid .wj-cell.wj-state-selected:not(.wj-state-invalid).first-cell.marked-for-deletion {
    background-color: #FFCD8C !important;
    box-shadow: none !important;
}

// custom cells
.custom-flex-grid .wj-cell.wj-state-selected.new-row  {
    background-color: #D1F4D9 !important;
}
.custom-flex-grid .wj-cell.wj-state-multi-selected.new-row  {
    background-color: #D1F4D9 !important;
}

.custom-flex-grid .wj-cell.wj-header.wj-header-alt.red-background {
    background-color: #FFECEF;
}

.custom-flex-grid .new-row {
    background-color: #E8F9EC !important;
}

.wj-error-tip {
    background: #D5255B !important;
    font-size: 12px !important;
    font-weight: 700 !important;
}

.entity-checkbox {
    display: flex;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    width: 100%;
}

.entity-checkbox .mdc-form-field {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 12px 24px;
}

.entity-checkbox .mdc-form-field label {
    cursor: move;
}

.entity-checkbox.marked-for-deletion.mat-mdc-checkbox-disabled label {
    color: #9A5300;
}

.entity-checkbox.green-text-and-bg .mdc-form-field {
    color: #007635;
}

.entity-checkbox .mdc-form-field>label {
    padding-left: 7px;
}

trovata-entities-review .entities-list {
    mat-expansion-panel.mat-expansion-panel:not([class*="mat-elevation-z"]),
    mat-expansion-panel.mat-expansion-panel {
        .mat-expansion-panel-header:hover{
            background: white !important;
        }
    }
}

trovata-grouped-entity-table .entities-list {
    mat-expansion-panel.mat-expansion-panel:not([class*="mat-elevation-z"]),
    mat-expansion-panel.mat-expansion-panel {
        border-radius: 0px;
        
        .mat-expansion-panel-header {
            border-radius: 0px;
        }
        
        .mat-expansion-panel-header.hover-row:hover {
            transition-duration: 0.2s;
            background: rgba(0, 108, 226, 0.12);
        }
        
        .mat-expansion-panel-header.hover-row:not(:hover) {
            transition-timing-function: ease-in-out;
        }
    }
}

.entities-list {
    mat-expansion-panel.mat-expansion-panel:not([class*="mat-elevation-z"]),
    mat-expansion-panel.mat-expansion-panel {
        box-shadow: none;

        .mat-expansion-panel-header,
        .mat-expansion-panel-header[aria-disabled='true'] {
            flex-direction: row-reverse;
            padding: 0px;
            height: auto;
            color: $dark-primary-text;
          
            .mat-content {
              padding-left: 12px;
            }

            .mat-content.mat-content-hide-toggle {
                margin: 0px;
                padding: 0px;
            }
          }

        div.mat-expansion-panel-body {
            padding: 0px;
        }
    }
    mat-expansion-panel.mat-expansion-panel:hover {
        background: none;
    }
}


.entities-account-chip {
    background: rgba(0, 0, 0, 0.12) !important;
    max-height: 20px !important;
    min-height: 20px !important;
    opacity: 1 !important;
    color: rgba(0, 0, 0, 0.60) !important;
}

.entities-regular-chip {
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 16px !important;
}

.entities-extra-chip {
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 16px !important;
}
.entity-personnel-table {
    td.mat-column-email {
        color: $primary-color;
        text-decoration: underline;
    }
}

.nav-menu-content-container:has(.entities-page) {
    margin: 0px 48px;
}

.entity-view {
    trovata-sticky-page-header {
        .header-wrapper {
            max-width: 340px;
            background-color: #F7F7F7;
            .header-container {
                margin: 0px 24px;
                max-width: 292px;
            }
        }
    }
    .entity-view-tabs mat-tab-body {
        padding: 0px;
        margin-top: 0px;
        div.mat-mdc-tab-body-content {
            padding: 24px;
            height: calc(100% - 48px);
        }
    }
    mat-card.mat-mdc-card, table.paginated-table.mat-mdc-table {
        background-color: #F7F7F7;
    }
}

.entity-view,
.entity-view-edit {
    .entity-view-tabs {
        height: calc(100vh - 64px);
        mat-tab-header.mat-mdc-tab-header {
             button.mat-mdc-tab-header-pagination.mat-mdc-tab-header-pagination-after,
             button.mat-mdc-tab-header-pagination.mat-mdc-tab-header-pagination-before,
             button.mat-mdc-tab-header-pagination.mat-mdc-tab-header-pagination-after.mat-elevation-z4,
             button.mat-mdc-tab-header-pagination.mat-mdc-tab-header-pagination-before.mat-elevation-z4 {
                margin: 12px 0px;
                box-shadow: none;
                width: 24px;
                min-width: 24px;
                height: 24px;
                border-radius: 12px;
                padding: 0px 4px;
                width: 12px;
                min-width: 12px;
             }

             button.mat-mdc-tab-header-pagination.mat-mdc-tab-header-pagination-after,
             button.mat-mdc-tab-header-pagination.mat-mdc-tab-header-pagination-after.mat-elevation-z4 {
               margin-right: 4px;
             }

             button.mat-mdc-tab-header-pagination.mat-mdc-tab-header-pagination-before,
             button.mat-mdc-tab-header-pagination.mat-mdc-tab-header-pagination-before.mat-elevation-z4 {
               margin-left: 4px;
             }

             .mat-mdc-tab-header-pagination-after  .mat-mdc-tab-header-pagination-chevron {
                transform: rotate(45deg) scale(0.7);
             }

             .mat-mdc-tab-header-pagination-before  .mat-mdc-tab-header-pagination-chevron {
                transform: rotate(-135deg) scale(0.7);
             }

             .mat-mdc-tab-labels div.mat-mdc-tab-label {
                padding: 0px 16px;
                opacity: 1;
                display: flex;
                min-width: unset;
                flex-basis: auto;
             }
        }
    }

    trovata-sticky-page-header {
        .header-wrapper {
            margin: 0px;
        }
    }

    .balances-grouping-table {
        .paginated-table {
            min-width: 290px;
        }
    }
}


.mat-mdc-menu-panel.entity-action-menu {
    min-width: 240px;
    width: 240px;
}