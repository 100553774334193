@import '_env-variables';

.trovata-payments-loading-icon {
	position: absolute;
	top: 43%;
	left: 48%;
}

.payments-header {
	height: 64px;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.payments-content-status {
	border-radius: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 15px;
}

.payments-on-chip {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #0db25f;
	color: white;
	padding: 3px 8px;
	font-weight: 400;
	font-size: 12px;
	line-height: 13px;
	border-radius: 16px;
	-webkit-border-radius: 16px;
	-moz-border-radius: 16px;
	-ms-border-radius: 16px;
	-o-border-radius: 16px;
}

.payments-off-chip {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #ff5252;
	color: white;
	padding: 3px 8px;
	font-weight: 400;
	font-size: 12px;
	line-height: 13px;
	border-radius: 16px;
	-webkit-border-radius: 16px;
	-moz-border-radius: 16px;
	-ms-border-radius: 16px;
	-o-border-radius: 16px;
}

.payments-account-chip {
	background-color: rgba(0, 0, 0, 0.12);
	border-radius: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 10px;
	margin: 0 10px 10px 0;
	-webkit-border-radius: 12px;
	-moz-border-radius: 12px;
	-ms-border-radius: 12px;
	-o-border-radius: 12px;
}

.payments-account-chip-large {
	height: 30px;
	background-color: rgba(0, 0, 0, 0.12);
	border-radius: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 10px;
	margin: 0 10px 10px 0;
	-webkit-border-radius: 12px;
	-moz-border-radius: 12px;
	-ms-border-radius: 12px;
	-o-border-radius: 12px;
}

.payments-chip-text {
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: $dark-primary-text;
	padding: 3px 5px;
}

.payments-card-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 48px;
	border-bottom: 1px solid $mat-card-light-border;
}

.payments-card-details-header {
	display: flex;
	align-items: center;
	height: 48px;
	border-top: 1px solid rgba(0, 0, 0, 0.16);
	border-bottom: 1px solid rgba(0, 0, 0, 0.16);
}

.payments-card-header-text {
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: $light-text-color;
	margin: 0 15px;
	letter-spacing: 4px;
}

.payments-card-content {
	display: flex;
	justify-content: space-between;
	padding: 15px;
}

.payments-person-chip {
	background-color: rgba(0, 0, 0, 0.12);
	border-radius: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 10px;
	margin: 0 10px 10px 0;
	-webkit-border-radius: 12px;
	-moz-border-radius: 12px;
	-ms-border-radius: 12px;
	-o-border-radius: 12px;
}

.payments-person-chip-large {
	height: 30px;
	background-color: rgba(0, 0, 0, 0.12);
	border-radius: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 10px;
	margin: 0 10px 10px 0;
	-webkit-border-radius: 12px;
	-moz-border-radius: 12px;
	-ms-border-radius: 12px;
	-o-border-radius: 12px;
}

.payments-small-card {
	background-color: #f0f0f0;
	border: none;
	border-radius: 8px !important;
	box-shadow: none;
	overflow: hidden;
	margin-right: 20px;
	min-width: 290px;
}

.mat-mdc-card:not([class*='mat-elevation-z']).payments-small-card {
	padding: 0px;
	box-shadow: none;
}

.payments-green-bolt {
	width: 20px;
	color: #64dc9a;
	margin-right: 10px;
}

.payments-user-avatar {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 32px;
	margin-left: -5px;
	background-color: #d3d3d3;
	border: 1px solid #ffffff;
	border-radius: 100%;
	-webkit-border-radius: 100%;
	-moz-border-radius: 100%;
	-ms-border-radius: 100%;
	-o-border-radius: 100%;
}

.payments-user-avatar-blue {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 32px;
	margin-left: -5px;
	background-color:  $primary-color;
	color: #ffffff;
	border: 1px solid #ffffff;
	border-radius: 100%;
	-webkit-border-radius: 100%;
	-moz-border-radius: 100%;
	-ms-border-radius: 100%;
	-o-border-radius: 100%;
}

.payments-workflow-step-spacer {
	width: 100%;
	height: 25px;
	display: flex;
	margin: 10px 0;
}

.payments-cancel-icon {
	cursor: pointer;
	color: rgba(0, 0, 0, 0.56);
}

.payments-pending-chip {
	background-color: #d6f0ff;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	-ms-border-radius: 10px;
	-o-border-radius: 10px;
}

.payments-rule-menu {
	min-width: 180px !important;
  }

  trovata-workflows-page app-paginated-table table th.mat-mdc-header-cell:nth-child(1),
  trovata-workflows-page app-paginated-table table td.mat-mdc-cell:nth-child(1) {
	width: 25vw;
  }

  trovata-payments-page .paginated-table,
  trovata-payment-detail-page .paginated-table {
	th.mat-mdc-header-cell.mat-column-paymentInitiatedDate,
	td.mat-mdc-cell.mat-column-paymentInitiatedDate,
	th.mat-mdc-header-cell.mat-column-paymentValueDate,
	td.mat-mdc-cell.mat-column-paymentValueDate,
	th.mat-mdc-header-cell.mat-column-batchPaymentInitiatedDate,
	td.mat-mdc-cell.mat-column-batchPaymentInitiatedDate,
	th.mat-mdc-header-cell.mat-column-batchPaymentValueDate,
	td.mat-mdc-cell.mat-column-batchPaymentValueDate {
	  min-width: 80px;
	}

	th.mat-mdc-header-cell.mat-column-openAmount,
	td.mat-mdc-cell.mat-column-openAmount,
	th.mat-mdc-header-cell.mat-column-completedAmount,
	td.mat-mdc-cell.mat-column-completedAmount,
	th.mat-mdc-header-cell.mat-column-batchPaymentType,
	td.mat-mdc-cell.mat-column-batchPaymentType {
	  border-left: 1px solid rgba($color: #000000, $alpha: 0.16);
	}

	th.mat-mdc-header-cell.mat-column-paymentId,
	td.mat-mdc-cell.mat-column-paymentId,
	th.mat-mdc-header-cell.mat-column-batchPaymentId,
	td.mat-mdc-cell.mat-column-batchPaymentId {
	  min-width: 155px;
	}

	th.mat-mdc-header-cell.mat-column-paymentFromAccount,
	td.mat-mdc-cell.mat-column-paymentFromAccount,
	th.mat-mdc-header-cell.mat-column-paymentToAccount,
	td.mat-mdc-cell.mat-column-paymentToAccount {
	  min-width: 90px;
	}

	th.mat-mdc-header-cell.mat-column-paymentName,
	td.mat-mdc-cell.mat-column-paymentName {
	  min-width: 90px;
	}

	th.mat-mdc-header-cell.mat-column-fromAcctName,
	td.mat-mdc-cell.mat-column-fromAcctName,
	th.mat-mdc-header-cell.mat-column-toAcctName,
	td.mat-mdc-cell.mat-column-toAcctName {
	  min-width: 100px;
	}

	th.mat-mdc-header-cell.mat-column-fromRoutingNumber,
	td.mat-mdc-cell.mat-column-fromRoutingNumber,
	th.mat-mdc-header-cell.mat-column-toRoutingNumber,
	td.mat-mdc-cell.mat-column-toRoutingNumber {
	  min-width: 100px;
	}

	th.mat-mdc-header-cell.mat-column-fromAccountNumber,
	td.mat-mdc-cell.mat-column-fromAccountNumber,
	th.mat-mdc-header-cell.mat-column-toAccountNumber,
	td.mat-mdc-cell.mat-column-toAccountNumber {
	  min-width: 70px;
	}

	th.mat-mdc-header-cell.mat-column-toAddressLineOne,
	td.mat-mdc-cell.mat-column-toAddressLineOne {
	  min-width: 85px;
	}

	th.mat-mdc-header-cell.mat-column-toBankAddressLineOne,
	td.mat-mdc-cell.mat-column-toBankAddressLineOne {
	  min-width: 115px;
	}

	th.mat-mdc-header-cell.mat-column-toAddressCity,
	td.mat-mdc-cell.mat-column-toAddressCity {
	  min-width: 75px;
	}

	th.mat-mdc-header-cell.mat-column-toBankAddressCity,
	td.mat-mdc-cell.mat-column-toBankAddressCity,
	th.mat-mdc-header-cell.mat-column-toBankAddressState,
	td.mat-mdc-cell.mat-column-toBankAddressState,
	th.mat-mdc-header-cell.mat-column-toBankAddressZip,
	td.mat-mdc-cell.mat-column-toBankAddressZip {
	  min-width: 110px;
	}

	th.mat-mdc-header-cell.mat-column-paymentTotal,
	td.mat-mdc-cell.mat-column-paymentTotal {
	  min-width: 50px;
	}

	th.mat-mdc-header-cell.mat-column-paymentStatus,
	td.mat-mdc-cell.mat-column-paymentStatus {
	  width: 100px;
	}
  }

  trovata-workflows-page app-paginated-table table th.mat-mdc-header-cell:nth-child(4),
  trovata-workflows-page app-paginated-table table td.mat-mdc-cell:nth-child(4) {
	width: 50px;
}

trovata-create-edit-workflow-dialog .mat-step-icon-selected,
trovata-create-edit-workflow-dialog .mat-step-icon-state-edit,
trovata-create-edit-template-dialog .mat-step-icon-selected,
trovata-create-edit-template-dialog .mat-step-icon-state-edit,
trovata-new-payment-dialog .mat-step-icon-selected,
trovata-new-payment-dialog .mat-step-icon-state-edit,
trovata-edit-account-dialog .mat-step-icon-selected,
trovata-edit-account-dialog .mat-step-icon-state-edit,
trovata-import-payments-dialog .mat-step-icon-selected,
trovata-import-payments-dialog .mat-step-icon-state-edit {
	background-color:  $primary-color !important;
}

trovata-new-payment-dialog .mat-horizontal-stepper-header-container,
trovata-create-edit-workflow-dialog .mat-horizontal-stepper-header-container,
trovata-create-edit-template-dialog .mat-horizontal-stepper-header-container,
trovata-edit-account-dialog .mat-horizontal-stepper-header-container,
trovata-import-payments-dialog .mat-horizontal-stepper-header-container {
	margin: 0 25px;
}

trovata-create-edit-workflow-dialog .maximum-payment .mat-form-field-outline-start,
trovata-create-edit-workflow-dialog .maximum-payment .mat-form-field-outline-gap,
trovata-create-edit-workflow-dialog .maximum-payment .mat-form-field-outline-end {
	background-color: white;
}

trovata-create-edit-workflow-dialog .mat-step-header[aria-selected='true'],
trovata-create-edit-template-dialog .mat-step-header[aria-selected='true'],
trovata-new-payment-dialog .mat-step-header[aria-selected='true'],
trovata-edit-account-dialog .mat-step-header[aria-selected='true'],
trovata-import-payments-dialog .mat-step-header[aria-selected='true'] {
	background-color: rgba(0, 0, 0, 0.04);
}

.review-menu {
	min-width: 183px !important;
}

trovata-sandbox-toggle .mat-mdc-text-field-wrapper {
	padding-bottom: unset !important;
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 100px;
}

trovata-sandbox-toggle .mat-mdc-form-text-infix {
	padding: 0 17px 0 17px !important;
	border-top: unset;
}

trovata-sandbox-toggle .mat-mdc-form-field-subscript-wrapper {
	display: none;
}

trovata-sandbox-toggle .mat-form-field-disabled .mat-mdc-form-text-infix {
	padding: 0 0 0 17px !important;
	border-top: unset;
}

trovata-sandbox-toggle .mdc-line-ripple{
	display: none;
}

.sandbox-panel-class {
	margin-top: 50px !important;
}

mat-select[panelclass='sandbox-panel-class'].mat-select-disabled .mat-select-trigger .mat-select-arrow-wrapper {
	visibility: hidden;
}

.payments-new-payment-menu {
	max-width: 240px !important;
}

.payments-new-payment-menu-item {
	display: flex !important;
	flex-direction: column;
	overflow: unset !important;
	text-overflow: unset !important;
	white-space: unset !important;
	height: unset !important;
	padding: 8px 16px !important;
}

.payments-account-payment-type {
	padding: 4px 6.5px;
	background-color: #f0f0f0;
	border-radius: 2px;
	margin-right: 4px;
}

.payments-account-payment-type-text {
	font-weight: 400;
	font-size: 12px;
	line-height: 13px;
	color: rgba(0, 0, 0, 0.56);
}

trovata-new-payment-dialog .value-date-picker .mat-mdc-form-text-infix {
	display: flex;
	padding: 0.4em 0px !important;
}

trovata-new-payment-dialog .value-date-picker .mat-mdc-form-text-infix .mat-input-element {
	cursor: pointer;
}

.payment-status-cell {
	text-align: center;
	padding-left: 20px;
	padding-right: 20px;
	border-radius: 100px;
	margin-top: 10px;
	margin-bottom: 10px;
	-webkit-border-radius: 100px;
	padding-top: 10px;
	padding-bottom: 10px;
	width: 64px;
	-moz-border-radius: 100px;
	-ms-border-radius: 100px;
	-o-border-radius: 100px;
}

.payment-action-required {
	text-align: center;
	padding-left: 20px;
	padding-right: 20px;
	margin-top: 10px;
	margin-bottom: 10px;
	padding-top: 10px;
	padding-bottom: 10px;
	width: 64px;
	color: white;
	background:  $primary-color;
	border-radius: 4px;
	cursor: pointer;
}

.batchId-container {
	width: 50px;
	padding: 7px;
	padding-left: 11px;
	padding-right: 11px;
	border-radius: 50px;
	position: relative;
	height: 15px;
	-webkit-border-radius: 50px;
	-moz-border-radius: 50px;
	-ms-border-radius: 50px;
	-o-border-radius: 50px;
}

.batch-svg,
.batchId-last4 {
	display: inline-block;
}

.batch-svg {
	position: absolute;
	left: 10px;
	top: 9px;
}

.verified-icon,
.verified-description {
	display: inline;
}

.verified-icon {
	margin-left: 10px;
}

.verified-container:hover .tooltiptext {
	visibility: visible;
}

.verified-container {
	position: relative;
}

.verified-container .tooltiptext {
	visibility: hidden;
	width: 171px;
	height: 24px;
	font-weight: 700;
	font-size: 12px;
	line-height: 16px;
	background-color: #707070;
	color: #ffffff;
	text-align: center;
	border-radius: 4px;
	padding: 8px 8px 2px 8px;
	position: absolute;
	z-index: 1;
	top: calc(100% + 20px);
	left: 50%;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-ms-border-radius: 4px;
	-o-border-radius: 4px;
}

.batchId-last4 {
	position: absolute;
	left: 30px;
}

.batchId-container:hover {
	background-color: #e0e0e0;
}

.payments-dialog-left-width {
	width: 67%;
}

.payments-dialog-right-width {
	width: 33%;
}

.payments-dialog-city-state-zip-width {
	width: calc(33% - 8px) !important;
}

.payments-dialog-summary {
	display: flex;
	flex-direction: column;
	padding: 12px 16px 8px 16px;
	background-color: #f0f0f0;
	border-radius: 8px;
}

.payments-dialog-summary-width {
	width: calc(100% - 32px);
}

.payments-address-two-button {
	padding: unset;
}

.expand-collapse-animation {
	transition: height 0.3s;
	transition: all 0.3s ease-in-out;
	max-height: 0px;
	overflow: auto;
}

.expand-animation {
	transition: all 0.3s ease-in-out;
	max-height: 100vh;
}

trovata-payments-page .mat-mdc-tab-group.skeleton-loader .mat-ink-bar {
	display: none;
}

trovata-payments-page .mat-mdc-tab-header {
}

.payment-type-radio {
	border: 1px solid rgba(0, 0, 0, 0.16);
	border-radius: 4px;
	margin-right: 10px;
}

.payment-type-radio-text {
	padding: 10px 15px;
	font-weight: 700;
	font-size: 14px;
	line-height: 20px;
	color: rgba(0, 0, 0, 0.56);
}

.payments-status-dropdown {
	max-height: 264px !important;
}

trovata-international-payment-form .mat-form-field-appearance-outline .mat-mdc-text-field-wrapper {
	margin: 0 !important;
}

trovata-international-payment-form .mat-mdc-select-panel {
	height: 2.9em !important;
}

trovata-international-payment-form .mat-mdc-option {
	height: 2.9em !important;
}

trovata-admin-approval-dialog .mat-mdc-form-text-infix {
	padding-top: 5px !important;
	padding-bottom: 13px !important;
}

.country-flag {
	width: 24px;
	height: 16px;
	margin-top: 16px;
	margin-right: 16px;
	float: left;
}

.country-text {
	float: left;
}

.country-flag,
.country-text {
	display: inline-block;
}

.question-text {
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	display: flex;
	align-items: center;
	color:  $primary-color;
}

.supported-country-option {
	height: 2.85em !important;
	line-height: 2.85em !important;
}

.amount-select .mat-mdc-form-field-bottom-align::before {
  display: none;
}

.amount-select .mat-mdc-form-field-hint-wrapper {
	position: relative;
}
