.column-menu {
	position: absolute;
	z-index: 10;
	background-color: white;
	border-radius: 10px;
	height: 650px;
	width: 1100px;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
	overflow: auto;
	border: 1px solid rgba(0, 0, 0, 0.3);
	box-shadow: 0 3px 7px rgb(0 0 0 / 30%);
	padding: 10px;
}
