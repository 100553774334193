trovata-audit-logs-search .paginated-table {

    th.mat-header-cell:first-of-type,
    td.mat-cell:first-of-type:not(.cdk-column-expandedDetail) {
        padding-left: 12px !important;
        padding-right: unset;
        width: 24px;
        max-width: 24px;
    }

    td.mat-cell:first-of-type.mat-column-expandedDetail {
        padding-left: unset !important;
    }

    td.mat-cell:last-of-type.mat-column-expandedDetail {
        padding-right: unset !important;
    }

    tr.expanded-row {
        background-color: white !important;
    }
}