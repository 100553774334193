.search-bar-wrapper {
	display: inline-flex;
	justify-content: center;
	flex-direction: row;
	align-items: center;
	width: 99.7%;
	position: relative;
}

.search-bar-wrapper > trovata-loading-icon {
	margin-right: -40px;
	margin-left: 10px;
	margin-top: -12px;
}

.search-option-img {
	vertical-align: middle;
	margin-right: 8px;
}

[dir='rtl'] .search-option-img {
	margin-right: 0;
	margin-left: 8px;
}

.search-bar-v2 {
	.search-bar.chip-padding {
		.mat-mdc-form-text-infix {
			padding: 9px 0px;
		}
	}
}

app-select-filter {
	.mdc-text-field--filled{
		background-color: unset !important;
	}
}