trovata-currency-select{
	.mini{
		.mdc-line-ripple {
			display: none;
		}

		.mat-mdc-text-field-wrapper {
			padding: 0;
		}

		.mat-mdc-select-arrow svg{
			top: 9px;
		}

		.mdc-text-field--filled{
			background-color: unset !important;
		}
	}
}
