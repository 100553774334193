@use '@angular/material' as mat;

.custom-menu-table-controls-menu {
  width: 280px;
  .mat-mdc-menu-content {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

.column-controls-name-form {
	@include mat.form-field-density(-5);
	.mat-mdc-form-field-subscript-wrapper{
		display: none;
	}

  .mat-mdc-form-field-infix {
    width: auto;
  }
}

