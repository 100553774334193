$AkkuratLLWeb: 'AkkuratLLWeb';
$dark-primary-text: rgba(0, 0, 0, 0.88);
$mat-card-light-border: rgba(0, 0, 0, 0.16);
$mat-chip-color: rgba(224, 224, 224, 0.87);
$mat-form-field-outline-color: rgba(0, 0, 0, 0.12);
$light-text-color: rgba(0, 0, 0, 0.56);
$disabled-color: rgba(0, 0, 0, 0.38);
$bold: 600;
$input: mat-typography-level(inherit, 1.15, 400);
$trovata-mat-card: (
	border-style: solid,
	border-width: 1px,
	border-color: rgba(0, 0, 0, 0.16),
);
$primary: #0064fd;
$green: #038142;
$red: #d5255a;
$gray: #707070;
$surface: #f5f5f5;
$font-weight-bold: 700;

// CARD SIZES
$medium-card-width: 462px;
$medium-card-height: 264px;
$large-card-width: 462px;
$large-card-height: 424px;
$xlarge-card-width: 462px;
$xlarge-card-height: 524px;

// CARD ROWS
$card-row-height-small: 31.22px;
$card-row-height-medium: 42px;
$card-row-height-large: 50px;
$card-row-height-xlarge: 122.67px;

// PAGE MARGINS
$page-side-margin-wide: 15px;
$page-side-margin-medium: 48px;
$page-side-margin-normal: 85px;
$page-bottom-margin: 90px;

// SIDE PANEL
$side-panel-width: 340px;
$side-panel-width-large: 420px;
