@import '_env-variables';

.column-selector-menu {
  margin: 15px;
  display: flex;
  flex-direction: column;
  height: 100%;

	.column-menu-container {
    display: flex;
    height: calc(100% - 86px);
    overflow: hidden;
    flex: 1 1 auto;
	}

	.menu-column {
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    flex-direction: column;
	}

	.item-container {
    overflow: auto;
    display: flex;
    flex-direction: column;
    height: 100%;
	}

	.menu-column-header {
		margin-bottom: 5px;
		font-weight: 500;
		border-bottom: black;
		border-bottom-style: solid;
		border-bottom-width: 2px;
		margin-right: 12px;
		padding-bottom: 6px;
	}

	.input-container {
    display: flex;
    justify-content: space-between;
	}

	.config-input {
		width: 38%;
	}

	.text-filter {
		width: 62%;
	}

	.filter-input {
		width: 100%;
	}
}
