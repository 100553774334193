.query-builder {
	.added-options {
		max-width: 200px;
		padding: 0px 10px 0px 10px;
		align-items: center;
	}

	mat-checkbox:not(:last-child) .mdc-form-field {
		margin-bottom: 20px;
	}

	mat-checkbox .mdc-label {
		white-space: normal !important;
		width: 170px;
		text-align: left;
	}

	mat-checkbox .mdc-checkbox {
		margin: 2px 8px auto 0;
	}

	.mat-expansion-panel:not([class*='mat-elevation-z']) {
		box-shadow: none;
	}

	.mat-expansion-panel-header.mat-expanded {
		height: 48px;
	}
}
