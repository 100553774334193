@use '@angular/material' as mat;

app-scrollable-data-table,
.scrollable-data-table-dragging {
  font-size: 14px;
  line-height: 16px;

  mat-expansion-panel.mat-expansion-panel:not([class*="mat-elevation-z"]),
  mat-expansion-panel.mat-expansion-panel {
    font-size: 14px;
    line-height: 16px;
    box-shadow: none;

    mat-expansion-panel-header.mat-expansion-panel-header {
      font-size: 14px;
      line-height: 16px;
      height: auto;
      padding: 0px;

      span.mat-content {
        margin-right: 0px;
      }
    }

    .mat-expansion-panel-content {
      font-size: 14px;
      line-height: 16px;
    }

    div.mat-expansion-panel-body {
      padding: 0px;
    }
  }

  .scrollable-group-left-column,
  .title-item {
    height: calc(100% - 21px);
    min-height: calc(100% - 21px);
    min-width: calc(20% - 36px);
    padding: 10px 12px 10px 24px;
  }

  .child-rows-background {
    background: rgba(180, 180, 180, 0.2);
  }
}

.scrollable-data-table-dragging {
  border-radius: 4px;
  background: #e0ebfa;

  app-scrollable-group-container > mat-expansion-panel.mat-expansion-panel:first-child {
    background: #e0ebfa;
  }
}

.mat-expansion-panel-header[aria-disabled=true]{
  color: unset;
}

.scrollable-group-right-column{
  width: 40px;
  min-width: 40px;
  min-height: 40px;
  padding: 0px 8px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.alt-child-icon-button{
  @include mat.icon-button-density(-5);
}
