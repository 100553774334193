mat-form-field.custom-option-input{
	.mat-mdc-form-field-subscript-wrapper{
		display: none !important;
	}

	.mdc-text-field--filled{
		background-color: unset;
	}

	.mdc-text-field{
		padding: 0px;
	}
	.active-option{
		.mat-mdc-select-value-text{
			font-size: 14px;
		}
	}
}

trovata-date-range-picker {
	.mat-mdc-form-field.date-range-input {
		.mat-mdc-text-field-wrapper {
			.mat-mdc-form-field-text-suffix {
				margin-right: -9px !important;
				vertical-align: sub !important;
				color: rgba(0, 0, 0, 0.54) !important;
			}
		}
	}
	.mat-mdc-form-field-subscript-wrapper {
		display: none !important;
	}

	.mat-mdc-form-field-icon-suffix {
		padding: 0;
	}
}

.header-button-container {
	div {
	  div {
		trovata-date-range-picker {
		  .mat-mdc-form-field {
			.mat-mdc-text-field-wrapper {
			  padding-bottom: 0px !important;
			}
		  }
		}
	  }
	}
  }
  
  .date-picker-custom-cadence-panel{
	width: 200px !important;
    overflow: visible !important;
  }

