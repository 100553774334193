.analysis-settings-form-field {
	width: 100%;
	padding-bottom: 0 !important;
	margin-top: 5px;
	font-size: 14px;
}

app-analysis-data-table {
	app-scrollable-data-table {
		.summary-column {
			font-weight: 700;
		}
	}
}
