@use '@angular/material' as mat;
@import '_env-variables';


.forecast-chart-area {
  .forecast-chart {
    overflow: visible !important;

    .highcharts-container {
    overflow: visible !important;

    svg:not(:root) {
      overflow: visible !important;
    }
    }

  }
}

#forecastTenseContainer {
  display: -webkit-inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -webkit-box-pack: center;
  -webkit-flex-pack: center;
  -webkit-justify-content: center;
  -webkit-flex-align: center;
  -webkit-align-items: center;
  vertical-align: top;
  .mat-button-toggle-checked {
    color: white;
    background-color: $primary-color;;
  }

  .forecastTenseGroup {
    height: 36px;
  }
  .mat-button-toggle-label-content {
    line-height: 36px;
  }
}

.dialog-container {
  .mat-content {
    justify-content: space-between;
  }
}

  .search-bar+* {
	.search-qb .mat-mdc-menu-content {
	  text-align: center;
	  padding-top: 0px !important;
	  padding-bottom: 0px !important;
	  min-width: 240px;
	  max-width: none;
	}
}

  .forecast-snapshot-opt+* {
	.search-qb .mat-mdc-menu-content {
	  text-align: left;
	  margin-left: 15px;
	  min-width: 240px;
	}
}

.search-bar+* {
  .search-qb .mat-menu-content {
    text-align: center;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    min-width: 240px;
    max-width: none;
  }
}

.forecast-snapshot-opt+* {
  .search-qb .mat-menu-content {
    text-align: left;
    margin-left: 15px;
    min-width: 240px;
  }
}

.right-aligned-header>.mat-content {
  justify-content: space-between;
}

/* Fixes a weird alignment glitch in forecast */
.stream-data-header>.mat-content {
  position: relative;
  margin-right: -10px;
  overflow: visible;
}

.streams-page,
.forecasts-page,
.forecastv3-page-container {
  height: 100%;
}


  .analysis-dialog,
  .configure-data-source-container,
  .configure-data-source-container-short {
    .dialog-options-row {
    margin-top: 16px;
    }

    .breadcrumb-wrapper {
    margin-top: -16px !important;
    margin-bottom: 12px;
    }
    .mat-mdc-form-field-text-prefix {
    align-self: center;
    }
  }


.forecasts-list-table .paginated-table {
  .mat-column-menu {
    width: 150px;
  }
}

.forecastv3-page-header {
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.forecastv3-page-title {
  font-weight: 700;
  font-size: 16px;
  float: left;
  height: 24px;
  display: flex;
  align-items: center;
}

.page-title-navigation {
  cursor: pointer;
}

.page-title-navigation:hover {
  text-decoration: underline;
}

.forecastv3-header-right {
  display: flex;
  flex-direction: row;
  float: right;
  justify-content: space-between;
  align-items: center;

  trovata-date-range-picker .mat-mdc-form-field {
    .mat-mdc-text-field-wrapper {
    padding-bottom: 0px !important;
    }
  }
}

.forecast-date-form-group {
  padding-bottom: 20px;
  width: 100%;
}

.date-label {
  z-index: 1;
  font-size: 12px;
  margin-left: 10px;
  position: absolute;
  top: 13px;
  background: white;
  padding-left: 5px;
  padding-right: 5px;
  color: #777777;
}

.header-vertical-divider-container {
  margin-left: 30px;
  margin-right: 30px;
}

.header-vertical-divider {
  height: 32px;
}

.forecastv3-page-header-divider {
  border-top-width: 2px;
  padding-bottom: 24px;
}

trovata-forecasts-list,
trovata-streams-list {
  .list-container {
    .mat-mdc-card {
    app-paginated-table {
      .table-container {
      .table-wrapper {
        max-height: 700px;
      }
      }
    }
    }
  }
}

mat-chip.forecast-table-dropdown{
  .mdc-evolution-chip__text-label{ 
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.forecast-table-dropdown-arrow{
  @include mat.icon-density(-5);
}

mat-form-field.version-view-mode-select{
	.mat-mdc-form-field-subscript-wrapper{
		display: none !important;
	}
}

.version-name-input{
	@include mat.form-field-density(-5);
	.mat-mdc-form-field-subscript-wrapper{
		display: none;
	}
}

.cdk-overlay-pane {
  div.mat-mdc-menu-panel.version-select-mat-menu {
    max-width: none !important;
  }
}