.nav-menu-content-container:has(trovata-transactions-page) {
	margin: 0px;
}

.transaction-tag-create-menu.redesign-menu.mat-mdc-menu-panel {
	min-width: 320px;
	max-width: 320px;
	width: 320px;
}

.transaction-page-tables {
	.mdc-tab:last-of-type {
    	opacity: 1;
		flex-grow: 1;
		justify-content: flex-end;
  }

  .mdc-tab__content{
	pointer-events: all!important;
	}
}

#transactionsToggleAccountNumber-button {
	margin-left: 115px !important;
}
