@use '@angular/material' as mat;

.edit-mode-control {
	.mat-mdc-form-field-flex {
		height: 56px;
	}
}

.dashboard-container {
	.mat-mdc-tab-body.mat-mdc-tab-body-active {
		overflow: visible;
	}
	.mat-mdc-tab-body-content {
		overflow: visible;
	}
}

.dashboard-tab-input{
	@include mat.form-field-density(-5);
	.mat-mdc-form-field-subscript-wrapper{
		display: none;
	}
}

