.workbooks-add-data-stepper .mat-vertical-stepper-header {
	pointer-events: none !important;
}

.workbooks-add-data-stepper {
	margin-left: -24px;
}

.workbooks-sheet-card {
	.mat-mdc-form-field {
		.mat-mdc-text-field-wrapper {
			padding-bottom: 0;
		}
	}

	mat-form-field .mat-mdc-form-field {
		&-underline {
			position: relative;
			bottom: auto;
		}

		&-subscript-wrapper {
			position: static;
		}
	}
}

trovata-data-outlet-editor-dialog{
	.mdc-text-field--filled{
		background-color: unset !important;
	}
}

.workbooks-list-card .paginated-table {
	.mat-column-menu {
		width: 48px;
	}
	.share-badge {
		color: rgba(0, 0, 0, 0.56);
		font-family: 'Material Icons Outlined';

		.mat-badge-content {
			right: -1px;
			top: 11px;
			width: 10px;
			height: 10px;
			line-height: 8px;
			font-size: 8px;
			background-color: grey;
			display: inline-flex;
			padding: 2px;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}
}}

.workbooks-flex-sheet .wj-header.wj-cell {
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
    background-color: #F4F4F4;
}

.workbooks-flex-sheet .wj-cell {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
	color: #444;
    background-color: #fff; 
	border-right: 1px solid rgba(0, 0, 0, .12);
    border-bottom: 1px solid rgba(0, 0, 0, .12);
}

.workbooks-flex-sheet.wj-content {
	border: 1px solid  #E0E0E0;
}

.workbooks-flex-sheet .wj-alt:not(.wj-state-selected):not(.wj-state-multi-selected) {
    background-color: #fff;
}

.workbooks-flex-sheet .wj-rowheaders .wj-row:last-of-type .wj-cell,
.workbooks-flex-sheet .wj-cells .wj-row:last-of-type .wj-cell {
    border-bottom: none;
}

.workbooks-flex-sheet .wj-header.wj-state-multi-selected {
    background: #E0E0E0;
    border-right: 1px solid rgba(0,0,0,.12) !important;
    border-bottom: 1px solid rgba(0,0,0,.12) !important;
}

.workbooks-flex-sheet .wj-marquee {
	box-shadow: 0 0 0 1px $primary-color,inset 0 0 0 1px $primary-color;
}

.workbooks-flex-sheet .wj-tabholder .wj-sheet-tab ul li:not(.active):hover {
    background: #E0E0E0;
	color: rgba(0, 0, 0, 0.56);
    cursor: pointer;
}

.workbooks-flex-sheet .wj-tabholder .wj-sheet-tab ul li {
	background: none;
	border-left: 1px solid rgba(0,0,0,.12);
}

.workbooks-flex-sheet .wj-tabholder .wj-sheet-tab ul li.active {
	border-left: 1px solid rgba(0,0,0,.12);
}

.workbooks-flex-sheet .wj-tabholder .wj-new-sheet {
	background-color: #FFF;
}

.workbooks-flex-sheet .wj-tabholder {
	background: #F4F4F4;
}
