@use '@angular/material' as mat;

.reconciliation-snack-container {
	.currency-progress-bar.mat-progress-bar.mat-primary .mat-progress-bar-buffer {
		background-color: green;
	}
}

.shown-columns {
	.mat-mdc-menu-content {
		border: 0px;
	}
	.mat-mdc-text-field-wrapper {
		padding-bottom: 0px;
	}
}

.recon-column-opt + *,
.recon-row-opt + * {
	.col-menu .mat-mdc-menu-content,
	.row-menu .mat-menu-content {
		text-align: left;
		margin-left: 15px;
		min-width: 240px;
	}
}

.recon-data-header > .mat-content {
	height: 100%;
}

.custom-dialog-container.account-details .mat-mdc-dialog-container {
	overflow: auto;
}

.recon-chart-currency-select{
	@include mat.form-field-density(-5);
	.mat-mdc-form-field-subscript-wrapper{
		display: none;
	}
}
