$primary-color: #006ce2;
$secondary-loader-color: #1d242e;
$chart-accent-color: #006ce2;
$primary-light-color: #85B0F0;
$primary-light-color-hover: #c7d9e3;

// Custom Statements colors
$primary-color-chip-v2: $primary-color;
$primary-light-color-chip-v2: #E4F2FF;
$primary-light-color-chip-hover-v2: $primary-light-color-hover;


$active-menu-weight: unset;
$active-menu-color: #006ce2;

$forecast-chart-actuals-color: #00EBC1;

$date-picker-hover: rgba(0, 108, 226, 0.12);

// Custom Trovata Colors
// #1d242e
$mat-palette: (
  50: #bbbdc0,
  100: #bbbdc0,
  200: #8e9297,
  300: #61666d,
  400: #3f454d,
  500: #13181f,
  //main #1d242e
  600: #1a2029,
  700: #151b23,
  800: #11161d,
  900: rgb(10, 13, 18),
  A100: #006ce2,
  A200: #006ce2,
  A400: #0060ef,
  A700: #0055d5,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);
