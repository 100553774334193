@import '_env-variables';

.tag-dialog-container {
	.mat-mdc-dialog-container {
		margin-top: -10px;
		margin: 0 0 10px;
	}

	.mat-mdc-dialog-content {
		max-height: 85vh;
	}
}

.tag-info-tabs {
	.mat-mdc-tab-body-content {
		overflow: hidden;
	}
}

.tag-tree {
	.node-wrapper {
		margin-bottom: 10px;
	}

	tree-viewport {
		overflow-x: hidden;
	}

	.node-content-wrapper-active,
	.node-content-wrapper.node-content-wrapper-active:hover,
	.node-content-wrapper-active.node-content-wrapper-focused {
		background: $primary-color;
		color: white;
	}
}

.gl-tag-tree {
	tree-viewport {
		overflow: initial;
		overflow-x: hidden;
	}
}

trovata-gl-tags-admin {
	height: 100%;

	.mdc-text-field--filled{
		background-color: unset !important;
	}
}

trovata-gl-tags-page {
	height: 100%;

	.mdc-text-field--filled{
		background-color: unset !important;
	}

	> mat-tab-group {
		height: 100%;

		> div {
			height: 100%;

			> mat-tab-body {
				height: 100%;
			}
		}
	}

	app-gl-codes {
		height: 100%;
	}

	.flex-container {
		display: flex;
		height: 100%;
	}
}

trovata-tags-page {
	height: 100%;
}

trovata-tag-details {
	.tag-title-form {
		div.mat-mdc-text-field-wrapper {
			padding-bottom: 0px;
		}
		div.mat-mdc-form-text-infix {
			border-top-width: 0px;
		}

		input {
			font-weight: 700;
			font-size: 16px;
		}
		.mdc-line-ripple {
			display: none;
		}
	}

	.tag-transaction-tables {
		.mdc-tab:last-of-type {
			opacity: 1;
			flex-grow: 1;
			display: inline-flex;
			justify-content: flex-end;
		}

		.mat-mdc-tab.mat-mdc-tab-disabled .mdc-tab__ripple::before {
			background-color: none !important;
		}

		.tag-transaction-tables.new-tag-transactions {
			width: calc(100% - 160px);
			max-width: calc(100% - 160px);
		}
	}
}

.tag-chart-controls-menu.redesign-menu.mat-mdc-menu-panel {
	min-width: 228px;
	max-width: 228px;
	width: 228px;
}

.nav-menu-content-container:has(trovata-tags-page) {
	margin: 0px;
}

mat-form-field.tag-title-input{
	.mat-mdc-form-field-subscript-wrapper{
		display: none !important;
	}
}