.feedback-thumb {
  .mat-mdc-icon-button .mat-button-focus-overlay {
    display: none;
  }
}

.message-visual-toggle {
  .mat-button-toggle {
    color: rgba(0, 0, 0, 0.56) !important;
  }
  .mat-button-toggle-checked {
    color: rgba(0, 0, 0, 0.88) !important;
  }
  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    padding: 0 16px !important;
  }
  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 36px !important;
  }
}
