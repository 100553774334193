// setup
.connections-table {
	.mat-expansion-panel-header[aria-disabled='true'] {
		color: $dark-primary-text;
		padding: 0 22px 0 12px !important;
	}

	.mat-expansion-panel:not([class*='mat-elevation-z']) {
		box-shadow: none;
	}
}
