.box-shadow-table.mat-mdc-table,
mat-mdc-table.box-shadow-table {
	overflow: auto;
	box-shadow:
		0px 2px 1px -1px rgb(0 0 0 / 20%),
		0px 1px 1px 0px rgb(0 0 0 / 14%),
		0px 1px 3px 0px rgb(0 0 0 / 12%);
	width: 100%;

	.legacy-hover-row:hover {
		background: #e0ebfa;
	}
}
