@import '_env-variables';

// wijmo
.wj-cells .wj-cell.wj-state-selected {
	background: $primary-color !important;
}

.wj-flexgrid .wj-state-sticky .wj-header {
	opacity: 1 !important;
}

/*
// wijmo */

/* wijmo context menu styles */
.ctx-menu {
	min-width: 100px;
	background: white;
	overflow: hidden;
}

.ctx-menu .wj-listbox-item {
	text-align: left;
	color: $primary-color;
	font-weight: 500;
}

/* wijmo column picker/listbox */
.wj-listbox.column-picker {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 0 10px;
	columns: 4;
	/* IE fallback */
	padding: 12px;
	margin-left: 12px;
	margin-top: 26px;
	box-shadow:
		0 10px 20px rgba(0, 0, 0, 0.19),
		0 6px 6px rgba(0, 0, 0, 0.23);
}

.wj-listbox.trxn-column-picker {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 0 10px;
	columns: 4;
	/* IE fallback */
	padding: 12px;
	margin-left: 12px;
	margin-top: 26px;
	box-shadow:
		0 10px 20px rgba(0, 0, 0, 0.19),
		0 6px 6px rgba(0, 0, 0, 0.23);
	overflow: auto;
	height: 100%;
	width: 100%;
}

.wj-flex-grid.trxn-grid-downloader {
	z-index: -1;
}

.wj-listbox .wj-listbox-item > label {
	display: block;
	margin: 0 0 3px 0;
}

.wj-listbox .wj-listbox-item.wj-state-selected {
	background: transparent;
	color: inherit;
}

.wj-listbox .wj-listbox-item:hover {
	background: rgba(0, 0, 0, 0.05);
}

.wj-listbox .drop-marker {
	position: absolute;
	background: $primary-color;
	opacity: 0.5;
	pointer-events: none;
	z-index: 1000;
}

.wj-listbox .wj-listbox-item > label > input[type='checkbox']:checked {
	background-color: $primary-color !important;
	border-color: $primary-color !important;
	color: #ffffff !important;
}

// Force Wijmo to use our AkkuratLLWeb font
wj-flex-sheet {
  font-family: 'AkkuratLLWeb' !important;
	font-feature-settings: "dlig" 1, "ss01" 1, "ss02" 1, "ss03" 1, "ss05" 1 !important;
	font-size: 16px !important;
}
