.balances-grouping-table {
	.account-group-panel .mdc-line-ripple {
		display: none;
	}

	--table-min-width: 700px;

	.paginated-table {
		min-width: var(--table-min-width);

		.mat-column-acctName,
		.mat-column-institutionId {
			min-width: 100px;
			max-width: 120px;
			overflow: hidden !important;
			white-space: nowrap !important;
			text-overflow: ellipsis !important;
			padding-left: 12px;
		}

		.mat-column-entityName,
		.mat-column-entityAlias,
		.mat-column-entityRegion,
		.mat-column-entityDivision,
		.mat-column-entityGroupingId,
		.mat-column-regionGroupingId,
		.mat-column-divisionGroupingId,
		.mat-column-acctNum {
			min-width: 96px;
			max-width: 96px;
			overflow: hidden !important;
			white-space: nowrap !important;
			text-overflow: ellipsis !important;
			padding-left: 12px;
		}

		.mat-column-balance,
		.mat-column-convertedBalance {
			overflow: hidden !important;
			white-space: nowrap !important;
			min-width: 120px;
			padding-left: 24px;
		}

		.mat-column-currency,
		.mat-column-currencyConverted {
			width: 64px;
			padding-left: 12px;
		}

		.mat-column-lastRequested,
		.mat-column-lastUpdate {
			min-width: 135px;
			max-width: 135px;
			padding-left: 12px;
			text-align: left !important;
		}
		.mat-column-manualHTML {
			width: 45px;
			min-width: 45px;
			max-width: 45px;
			div {
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}

		.mat-column-type {
			max-width: 112px;
		}
	}
}

.balances-quick-edit {
	.mat-column-acctNum,
	.mat-column-acctName,
	.mat-column-entityName,
	.mat-column-entityAlias,
	.mat-column-entityRegion,
	.mat-column-entityDivision,
	.mat-column-entityGroupingId,
	.mat-column-regionGroupingId,
	.mat-column-divisionGroupingId,
	.mat-column-type {
		min-width: 100px;
		width: 14.6%;
	}

	.mat-column-acctName:not(.mat-mdc-header-cell) > *,
	.mat-column-entityGroupingId:not(.mat-mdc-header-cell) > *,
	.mat-column-regionGroupingId:not(.mat-mdc-header-cell) > *,
	.mat-column-divisionGroupingId:not(.mat-mdc-header-cell) > *,
	.mat-column-type:not(.mat-mdc-header-cell) > * {
		border-radius: 12px;
		text-align: left;
		padding: 4px 8px;
		align-content: center;
		flex-direction: row;
	}

	.mat-column-acctName:not(.mat-mdc-header-cell) > *:hover,
	.mat-column-entityGroupingId:not(.mat-mdc-header-cell) > *:hover,
	.mat-column-regionGroupingId:not(.mat-mdc-header-cell) > *:hover,
	.mat-column-divisionGroupingId:not(.mat-mdc-header-cell) > *:hover,
	.mat-column-type:not(.mat-mdc-header-cell) > *:hover {
		border-radius: 12px;
		background: rgba(0, 0, 0, 0.08);
	}

	.mat-column-acctName:not(.mat-mdc-header-cell) > *:focus,
	.mat-column-entityGroupingId:not(.mat-mdc-header-cell) > *:focus,
	.mat-column-regionGroupingId:not(.mat-mdc-header-cell) > *:focus,
	.mat-column-divisionGroupingId:not(.mat-mdc-header-cell) > *:focus,
	.mat-column-type:not(.mat-mdc-header-cell) > *:focus {
		border-radius: 12px;
		background: rgba(0, 0, 0, 0.16);
	}

	.mat-column-currency {
		min-width: 64px;
		width: 64px;
	}

	.mat-column-manualHTML {
		width: 45px;
		min-width: 45px;
		max-width: 45px;
		padding: 0 6px !important;
		padding-right: 16px !important;
		padding-left: 16px;
	}
}

.balances-table {
	.paginated-table {
		min-width: 700px;

		tr.mat-mdc-header-row {
			vertical-align: baseline;
			height: 24px;
		}
	}

	.table-wrapper {
		display: block;
		overflow: auto;
		width: 100%;
	}
}

.shown-col-menu.mat-mdc-menu-panel {
	min-width: 300px;
}

.shown-col-menu .mat-mdc-menu-content {
	text-align: left;
	min-width: 300px;
}

#bulk-manual-account-balance-dialog .wj-cell {
	text-align: right;
}

.shown-col-menu {
	width: 250px;

	.mat-mdc-menu-content {
		width: 250px;
		text-align: left;
	}

	.balances-shown-col-list {
		.mat-slide-toggle-label {
			justify-content: space-between;
		}
	}
}

.manual-account-entry {
	.mat-vertical-content {
		mat-form-field:not([hintLabel]) {
			.mat-mdc-text-field-wrapper {
				padding: 0px;
			}
		}

		mat-form-field[hintLabel] {
			.mat-mdc-text-field-wrapper {
				padding-bottom: 14px;
			}
		}
	}
}

.snack-selector-v2 {
	.mat-column-date {
		align-content: flex-end;
		text-align: right;
		justify-content: right;
	}
}

#balancesToggleAccountNumber-button {
	margin-left: 110px !important;
}
