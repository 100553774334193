@import '_env-variables';

$t-dialog-header-height: 64px;

// Overall Container
.side-stepper-dialog-container {
	border-radius: 0px;
}

.side-stepper-dialog-header-container {
	height: $t-dialog-header-height;
	display: inline-flex;
	align-items: center;
	box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.16);
	width: 100%;
}

// Dialog Header
.side-stepper-dialog-header {
	margin-left: 16px;
	margin-right: 24px;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
}

.side-stepper-dialog-header-left {
}

.side-stepper-dialog-header-title {
	vertical-align: middle;
	margin-left: 4px;
}

.side-stepper-dialog-header-middle {
}

.side-stepper-dialog-header-right {
}

// Dialog Content
.side-stepper-dialog-content-container {
	overflow-y: auto;
	height: calc(100vh - $t-dialog-header-height - 48px);

	.side-stepper-dialog-content-wrapper {
		padding-bottom: 80px;
	}

	.mat-mdc-form-field {
		width: 100%;
	}
	.mat-mdc-form-field-text-prefix {
		align-self: center;
	}

	// vertical-stepper
	mat-vertical-stepper {
		.mat-step-header {
			font-weight: 700 !important;
			font-size: 14px !important;

			.cdk-program-focused {
				background: rgba(0, 0, 0, 0.04) !important;
			}
		}
	}

	// review-step
	.review-container {
		border: solid rgba(0, 0, 0, 0.16) 1px;
		border-radius: 4px;
		margin-left: 24px;
		margin-right: 24px;

		.review-item {
			border: solid rgba(0, 0, 0, 0.16) 1px;
			border-radius: 4px;
			padding: 24px;

			.step-review-header {
				font-weight: 400;
				color: rgba(0, 0, 0, 0.56);
				font-size: 12px;
				letter-spacing: 4px;
				display: block;
				text-transform: uppercase;
				margin-bottom: 16px;
			}

			.step-review-row {
				margin-bottom: 7px;
			}

			.step-review-item {
				font-size: 14px;
				font-weight: 700;
			}

			.step-review-value {
				font-size: 14px;
				font-weight: 400;
			}

			.mat-divider.review-divider {
				margin-top: 32px;
				margin-bottom: 16px;
			}
		}

		.review-button-container {
			margin-top: 24px;
		}
	}
}
